import {
  Component, Input, OnInit, Output, EventEmitter, OnChanges
} from '@angular/core';

export type PageUpdateAdd = 'append' | 'prepend';

export interface PageUpdateEvent {
  page: number;
  count: number;
  add?: PageUpdateAdd;
}

@Component({
  selector: 'agd-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() totalItems: number;

  @Input() pageSize: number;

  @Input() page: number;

  @Output() pageChange = new EventEmitter<PageUpdateEvent>();

  pages: number;

  private _currentPage = 1;

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(page) {
    this._currentPage = page;
    this.pageChange.emit({
      page: this._currentPage,
      count: this.pageSize,
    });
  }

  ngOnInit(): void {
    this.initPages();
  }

  ngOnChanges(): void {
    this.initPages();
  }

  nextPage(): void {
    this.currentPage += 1;
  }

  previuosPage(): void {
    this.currentPage -= 1;
  }

  redirectToPage(page: number) {
    this.currentPage = page;
  }

  private initPages() {
    this._currentPage = this.page || 1;

    const size = this.pageSize >= 1 ? this.pageSize : 8;
    // Math.ceil is used for rounded up (total 'consultas' / size) and calculate the total number pages
    this.pages = Math.ceil(this.totalItems / size);
  }
}
